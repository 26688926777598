<template>
    <div>
        <div
            v-if="userInfo.area === 'JP'"
            :class="$style.tips"
            v-html="
                $t('users.query_data_large_tips', {
                    link: link
                })
            "
        ></div>
        <div v-else :class="$style.tips" v-html="$t('users.query_data_large_tips_en')"></div>
    </div>
</template>

<script>
import docsUrl from '@common/configs/docsUrl';
import { mapGetters } from 'vuex';

export default {
    name: 'UserGroupTips',

    data() {
        return {
            link: docsUrl.setOut
        };
    },
    computed: {
        ...mapGetters('main', ['userInfo'])
    }
};
</script>

<style lang="scss" module>
@import '@/styles/import.scss';
.tips {
    width: 310px;
    color: $pt-black-90;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
    margin: 80px auto;
    grid-column-start: span 12;

    a {
        @extend %link;
        color: $pt-black-90;
    }
}
</style>

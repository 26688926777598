<template>
    <div :class="[$style.setting, !visibleOverview && $style['setting-mBottom']]">
        <page-header
            ref="header"
            size="full"
            :style="headerStyle"
            :class="$style.header"
            :visibleBack="true"
            :hasBorder="true"
            :backText="backText"
            :title="usersTitle"
            @back="handleBackClick"
        >
            <div ref="headerTools" :class="$style['header-tools']" slot="rightTools">
                <pt-icon
                    v-show="headerStyle.top === '0'"
                    @click="toggleDialogVisible('description', !dialogVisible.description)"
                    icon="icon-recommend"
                    :icon-style="{
                        width: '24px',
                        height: '24px'
                    }"
                ></pt-icon>

                <pt-button size="medium" :loading="saving" :disabled="!usersCanSave" @click="handleSaveClick">{{
                    $t('users.save')
                }}</pt-button>
            </div>
        </page-header>

        <div
            class="x-wrap x-wrap--full"
            :class="[$style.description, guideInfo && $style[`description-${guideInfo.code}`]]"
            v-show="dialogVisible.description"
        >
            <div :class="$style['description-arrow']" :style="descriptionArrowStyle"></div>
            <div :class="$style['description-container']">
                <pt-icon
                    :class="$style['description-close']"
                    @click="toggleDialogVisible('description', false)"
                    icon="pt-icon--close"
                    :icon-style="{
                        width: '16px',
                        height: '16px'
                    }"
                ></pt-icon>
                <div :class="$style['description-icon']">
                    <pt-icon
                        :icon="guideInfo.icon"
                        :icon-style="{
                            width: '115px',
                            height: '115px',
                            fill: '#00A92E'
                        }"
                    ></pt-icon>
                </div>
                <div :class="$style['description-info']">
                    <h3>{{ $t(guideInfo.title || guideInfo.name) }}</h3>
                    <p>{{ $t(guideInfo.info) }}</p>
                    <div :class="$style['description-items']">
                        <div :class="$style['description-item']">
                            <h4 v-html="$t(guideInfo.definition_title || 'users.definition')"></h4>
                            <span>{{ $t(guideInfo.definition) }}</span>
                        </div>
                        <div :class="$style['description-item']">
                            <h4 v-html="$t(guideInfo.target_title || 'users.target')"></h4>
                            <span>{{ $t(guideInfo.target) }}</span>
                        </div>
                        <div :class="$style['description-item']">
                            <h4 v-html="$t(guideInfo.measure_title || 'users.measure')"></h4>
                            <span>{{ $t(guideInfo.measure) }}</span>
                            <!--
                                <h5>[{{$t('users.use_case')}}]</h5>
                                <a>
                                    初めて新規で訪問したユーザーに対して
                                    <br />リピード未コンバージョンユーザーに対して
                                </a>
                            -->
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div :class="$style.container">
            <div class="x-wrap x-wrap--full">
                <div :class="$style.box">
                    <h4>{{ $t('users.users_name') }}</h4>
                    <div :class="$style['box-content']">
                        <pt-input
                            :class="$style['box-ipt']"
                            size="large"
                            :placeholder="$t('users.users_untitled')"
                            v-model.trim="model.name"
                            @keyup.enter="handleIptBlur($event, 'name')"
                            @blur="handleIptBlur($event, 'name')"
                        ></pt-input>
                    </div>
                    <pt-button
                        type="text"
                        color="black"
                        v-if="users.description === null"
                        @click="handleDescriptionClick"
                        >+{{ $t('users.users_description') }}</pt-button
                    >
                </div>

                <div :class="$style.box" v-if="users.description !== null">
                    <h4>
                        <span>{{ $t('users.users_description') }}</span>
                        <el-tooltip :content="$t('annotation.user_group_4')">
                            <pt-icon
                                icon="pt-icon--help-new"
                                :icon-style="{
                                    width: '16px',
                                    height: '16px',
                                    fill: '#5E6C84',
                                    margin: '0 0 2px 6px'
                                }"
                            ></pt-icon>
                        </el-tooltip>
                    </h4>
                    <div :class="$style['box-content']">
                        <pt-input
                            :class="[$style['box-ipt'], $style['box-textarea']]"
                            type="textarea"
                            size="large"
                            :rows="2"
                            v-model="users.description"
                        ></pt-input>
                    </div>
                </div>

                <div :class="$style.box">
                    <h4>{{ $t('users.users_conditions') }}</h4>
                    <div :class="$style['box-content']">
                        <template v-if="users.content && users.content.children && userGroupType">
                            <filter-content
                                v-for="(filter, index) in users.content.children"
                                :key="filter.uuid"
                                :sid="sid"
                                :timeZone="timeZone"
                                :filter="filter"
                                :filter-len="users.content.children && users.content.children.length"
                                :filter-items="userGroupType"
                                :value-options="valueOptions"
                                @updateItems="handleFilterItemsUpdate"
                                @delete="val => handletFilterDelete(index, val)"
                                @change="val => handleFilterChange(index, val)"
                            ></filter-content>
                        </template>
                        <button :class="$style['filter-add']" @click="handleFilterCreate">
                            {{ $t('users.add_condition') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="x-wrap x-wrap--full" v-if="hasExceededData">
            <user-group-tips></user-group-tips>
        </div>

        <div class="x-wrap x-wrap--full" :class="$style.overview" v-else-if="visibleOverview">
            <div :class="$style['overview-title']" v-loading="totals.loading">
                <template v-if="totals.data">
                    <h4>{{ $t('users.create_preview') }}</h4>
                    <p>
                        {{ $t('users.overview_total_title') }}
                        <span>{{ totals.data.users }}</span>
                        <i></i>
                        {{ $t('users.overview_active_users') }}
                        <span>{{ totals.data.activeUsers }}</span>
                        <el-tooltip :content="$t('annotation.user_group_5')">
                            <pt-icon
                                icon="pt-icon--help-new"
                                :icon-style="{
                                    width: '16px',
                                    height: '16px',
                                    fill: '#5E6C84',
                                    margin: '0 0 10px 6px'
                                }"
                            ></pt-icon>
                        </el-tooltip>
                    </p>
                </template>
            </div>

            <div :class="$style['overview-list']" v-loading="userList.loading" element-loading-background="transparent">
                <template v-if="!userList.loading">
                    <template v-if="userList.showData && userList.showData.length > 0">
                        <div :class="[$style.row, $style['row-header']]">
                            <div
                                v-for="cell in userList.header"
                                :class="[$style.cell, $style[`cell-span${cell.span}`], $style[cell.code]]"
                                :key="cell.code"
                            >
                                {{ cell.key }}
                            </div>
                        </div>
                        <div :class="$style.row" v-for="(row, $index) in userList.showData" :key="$index">
                            <div
                                v-for="cell in userList.header"
                                :class="[$style.cell, $style[`cell-span${cell.span}`], $style[cell.code]]"
                                :key="cell.code"
                            >
                                <span
                                    :style="cell.code === 'icon' && row.iconStyle"
                                    :title="cell.key && row[cell.showKey || cell.key]"
                                    >{{ cell.key ? row[cell.showKey || cell.key] : getIcon(row) }}</span
                                >
                            </div>
                        </div>
                        <div :class="$style.lenTips" v-if="visibleCountTips">
                            {{ $t('users.users_report_limit', { num: userList.limit }) }}
                        </div>
                    </template>
                    <div v-else :class="$style.noData">{{ $t('users.users_report_no_data') }}</div>
                </template>
            </div>
        </div>

        <div class="x-wrap x-wrap--full" :class="$style.overview" v-else>{{ $t('users.overview_no_users') }}</div>

        <!-- 确认返回弹框-->
        <back-dialog
            v-if="dialogVisible.backDialog"
            @cancel="toggleDialogVisible('backDialog', false)"
            @confirm="handleConfirmBack"
        ></back-dialog>
    </div>
</template>

<script>
import axios from 'axios';
import dayjs from 'dayjs';
import isEqual from 'lodash/isEqual';
import { mapMutations, mapActions, mapState, mapGetters } from 'vuex';
import numberUtils from '@/common/utils/number.utils';
import usersApis from '@/apis/users.apis';
import BackDialog from './dialogs/BackDialog';
import FilterContent from './filter/content/Filter';
import PageHeader from '@/common/packages/page-header/PageHeader';
import cloneUtils from '@/common/utils/clone.utils';
import commonUtils from '@/common/utils/common.utils';
import dateUtils from '@/common/utils/date.utils';
import { searchTree, getDefaultFilter, getDefaultValue, initUsergroupData } from '../usergrp-util';
import analyticsService from '@/common/services/analytics.service';
import usersConfig from '../usergrp-config.js';
import UserGroupTips from './UserGroupTips';

var relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);

export default {
    name: 'UsersCreate',

    data() {
        const guideType = this.$route.query.users;
        const defaultGuideInfo = usersConfig.guideInfo.find(item => item.default);
        const guideInfo =
            (guideType && usersConfig.guideInfo.find(item => item.code === guideType)) || defaultGuideInfo;
        const isCreated = this.$route.name === 'UsersCreate';
        const userGroupId = this.$route.params.usersId;
        const dialogVisible = {
            backDialog: false,
            description: isCreated
        };
        const unkoneColor = '#8993A4';
        const colors = ['#4589FF', '#66D7AC', '#F0C800', '#FF8475'];
        const header = [
            {
                code: 'icon',
                span: 1
            },
            {
                key: 'name',
                showKey: 'showName',
                code: 'name',
                type: 'CUSTOM',
                patternType: 'STRING',
                span: 3
            },
            {
                key: 'email',
                showKey: 'showEmail',
                code: 'email',
                type: 'CUSTOM',
                patternType: 'STRING',
                span: 2
            },
            {
                key: 'last active',
                showKey: 'showActive',
                code: 'active',
                type: 'STATIC',
                patternType: 'TIME',
                span: 2
            },
            {
                key: 'city',
                showKey: 'showCity',
                code: 'city',
                type: 'STATIC',
                patternType: 'STRING',
                span: 2
            },
            {
                key: 'total sessions',
                showKey: 'showSessions',
                code: 'sessions',
                type: 'STATIC',
                patternType: 'NUMBER',
                span: 2
            }
        ];
        const properties = header.reduce((acc, cur) => {
            if (cur.key) {
                const { key, type, patternType } = cur;
                acc.push({ key, type, patternType });
            }
            return acc;
        }, []);
        const { from, engageId } = this.$route.params;
        const defaultUsers = {
            name: (guideInfo.code !== 'custom' && this.$t(guideInfo.name)) || this.$t('users.users_untitled'),
            description: null,
            guideType: guideInfo.guideType,
            content: getDefaultFilter(guideType)
        };
        return {
            colors,
            unkoneColor,
            dialogVisible,
            defaultGuideInfo,
            from,
            engageId,
            guideType,
            guideInfo,
            loading: true,
            saving: false,
            isCreated,
            userGroupId,
            userGroupType: null,
            valueOptions: {},
            model: {
                name: ''
            },
            hasExceededData: false,
            queryDataExceededErrorCode: 'QUERY_DATA_EXCEEDED',
            date: {
                startTime: dateUtils.getMomentTime({
                    num: -6,
                    type: 'day',
                    isStart: true,
                    timezone: this.timeZone
                }),
                endTime: dateUtils.getMomentTime({
                    num: 0,
                    type: 'day',
                    isStart: true,
                    timezone: this.timeZone
                })
            },

            totals: {
                data: null,
                showData: {},
                loading: true,
                queryStatus: 'start', //start/pending/success/error
                requestId: 'FETCH_USERS_OVERVIEW_TOTALS_ID'
            },

            defaultUsers,
            users: cloneUtils.deep(defaultUsers),

            userList: {
                header,
                data: null,
                showData: [],
                loading: true,
                limit: 200,
                itemLen: 0, //已显示条数
                queryStatus: 'start', //start/pending/success/error
                requestId: 'FETCH_USERS_OVERVIEW_LIST_ID',
                properties
            },

            headerStyle: {
                top: '0'
            }
        };
    },

    computed: {
        ...mapState('main', ['profileInfo']),
        ...mapState('users', ['userProperties']),
        ...mapGetters('users', ['usersFilter']),

        sid() {
            return this.profileInfo.sid;
        },

        timeZone() {
            return this.profileInfo.timeZone;
        },

        backText() {
            const backToCampaignRouter = ['CampaignEdit', 'CampaignCreate', 'EngagementCreate', 'EngagementEdit'];
            return backToCampaignRouter.includes(this.from)
                ? this.$t('campaign_settings.back_to_campaign')
                : this.$t('users.back');
        },

        usersTitle() {
            return this.isCreated ? this.$t('users.create_users_title') : this.$t('users.edit_users_title');
        },

        usersCanSave() {
            const { name, content } = this.users;
            const { children } = content || { children: [] };
            return (
                name &&
                children.some(item => {
                    const { error } = item || {};
                    return error ? Object.keys(error).every(o => !error[o]) : true;
                })
            );
        },

        //过滤值，去除不完善或者错误的过滤条件
        currentContent() {
            const { content } = this.users || {};
            const { children = [], operator = 'and' } = content || {};
            return {
                children: children.reduce((acc, cur) => {
                    const { error = {} } = cur || {};
                    Object.keys(error).every(key => !error[key]) && acc.push(cur);
                    return acc;
                }, []),
                operator
            };
        },

        visibleOverview() {
            const { children } = this.currentContent || { children: [] };
            return (
                !this.isCreated ||
                (children &&
                    children.length > 0 &&
                    (this.totals.data || (this.userList.showData && this.userList.showData.length > 0)))
            );
        },

        visibleCountTips() {
            const { data, showData } = this.userList;
            return data && showData && data.length === showData.length;
        },

        descriptionArrowStyle() {
            return {
                right: this.$i18n.locale === 'EN' ? '160px' : '108px'
            };
        }
    },

    async created() {
        const [err, properties] = await commonUtils.awaitWrap(this.getUsersProperties());

        if (err || !properties) {
            console.log('Get user properties failed.');
        } else {
            const userProperties = properties
                ? {
                      label: this.$t('dimension.user_properties'),
                      options: properties
                  }
                : [];
            const basicFilterItems = cloneUtils.deep(usersConfig).audienceType.map(item => {
                const { label, options } = item;
                return {
                    label,
                    options: options.map(o => {
                        const { code, matchRange } = o;
                        o.code = `${code}_${matchRange}`;
                        o.key = code;
                        return o;
                    })
                };
            });
            this.userGroupType = [...basicFilterItems, userProperties];

            if (this.isCreated) {
                this.model.name = this.users.name;
                this.loading = false;
            } else {
                let [err, users] = await commonUtils.awaitWrap(this.fetchUserGroupInfo());
                if (err || !users) {
                    this.handleConfirmBack();
                } else {
                    users.guideType || (users.guideType = this.defaultGuideInfo.guideType);
                    this.users = Object.assign(users, {
                        content: initUsergroupData(users?.content)
                    });
                    this.defaultUsers = cloneUtils.deep(users);
                    this.model.name = users.name;
                    users.guideType &&
                        (this.guideInfo = usersConfig.guideInfo.find(item => item.guideType === users.guideType));
                }
                this.loading = false;
            }
        }
        this.$eventBus.$on('mainScroll', this.updateHeaderPosition, false);
        this.$eventBus.$on('mainScrollReachEndY', this.updateShowUserList);
        // window.addEventListener('resize', this.handleResize, false);
    },

    beforeDestroy() {
        this.$eventBus.$off('mainScroll', this.updateHeaderPosition, false);
        this.$eventBus.$off('mainScrollReachEndY', this.updateShowUserList);
        // window.removeEventListener('resize', this.handleResize, false);
    },

    mounted() {
        this.isCreated && this.fetchUserList();
        this.fetchTotalsData();
    },

    methods: {
        ...mapActions('main', ['updateProfileGuideInfo']),
        ...mapActions('users', ['getUsersProperties']),
        ...mapMutations('main', ['UPDATE_COUNTS', 'UPDATE_PACKAGE_USAGE', 'SET_AUTH_DIALOG_VISIBLE']),
        ...mapMutations('users', ['UPDATE_USER_GROUP_INFO', 'UPDATE_USER_GROUP_NAME']),

        getIcon({ email }) {
            return (email || '?')[0].toLocaleUpperCase();
        },

        getIconStyle(email) {
            const index = Math.floor(Math.random() * this.colors.length);
            return {
                'background-color': email ? this.colors[index] : this.unkoneColor
            };
        },

        requestStandardData(funName, requestId) {
            const { sid, timeZone } = this.profileInfo;
            const { startTime, endTime, timeType } = this.date;
            return usersApis.standardData(
                null,
                {
                    where: {
                        sid,
                        funName,
                        startTime,
                        endTime,
                        timeModel: 'day',
                        timeType,
                        timeZone,
                        audienceFilter: this.users.content,
                        sequentialFlag: 1,
                        filter: {}
                    }
                },
                { requestId }
            );
        },

        fetchUserGroupInfo() {
            return usersApis.getUserGroupInfo(null, {
                where: {
                    sid: this.profileInfo.sid,
                    id: this.userGroupId
                }
            });
        },

        async fetchUserList() {
            this.hasExceededData = false;
            this.userList.loading = true;
            this.userList.queryStatus = 'pending';
            axios.cancel(this.userList.requestId);

            const [err, detailData] = await commonUtils.awaitWrap(
                usersApis.detailData(
                    null,
                    {
                        where: {
                            sid: this.profileInfo.sid,
                            funName: 'userList',
                            audienceFilter: this.users.content,
                            timeZone: this.profileInfo.timeZone,
                            limit: this.userList.limit,
                            properties: this.userList.properties,
                            sort: 'total sessions'
                        }
                    },
                    { requestId: this.userList.requestId }
                )
            );

            this.userList.showData = [];
            if (err || !detailData) {
                this.userList.data = [];
                this.userList.queryStatus = 'error';
            } else if (!axios.isCancel(detailData)) {
                this.userList.data = (detailData.user_list || []).map(item => {
                    const lastActiveTime = item['last active'];
                    item['showActive'] = '--';

                    // 时间戳类型
                    if (lastActiveTime && lastActiveTime.length === 13) {
                        const date = new Date(Number(lastActiveTime));
                        !Number.isNaN(date.getTime()) && (item['showActive'] = dayjs(date).fromNow());
                    }
                    item.showName = item.name || '--';
                    item.showEmail = item.email || '--';
                    item.showCity = item.city || '--';
                    item['showSessions'] = `${item['total sessions']} sessions`;
                    // item['showActive'] = (item['last active'] && dayjs(item['last active']).fromNow()) || '--';
                    item.iconStyle = this.getIconStyle(item.email);
                    return item;
                });
                this.updateShowUserList(true);
                this.userList.queryStatus = 'success';
            }
            this.userList.loading = false;
        },

        async fetchTotalsData() {
            this.totals.loading = true;
            this.totals.data = null;
            this.totals.queryStatus = 'pending';
            axios.cancel(this.totals.requestId);

            const [err, standardData] = await commonUtils.awaitWrap(
                this.requestStandardData('overviewMetric', this.totals.requestId)
            );
            if (err || !standardData) {
                this.totals.queryStatus = 'error';
                err?.code === this.queryDataExceededErrorCode && (this.hasExceededData = true);
            } else if (!axios.isCancel(standardData)) {
                const { activeUsers, users } = standardData.currentResult || {};
                this.totals.data = {
                    users: numberUtils.formatNumber(users),
                    activeUsers: numberUtils.formatNumber(activeUsers)
                };
                this.totals.queryStatus = 'success';
            }
            this.totals.loading = false;
        },

        handleReachEndY() {
            this.updateShowUserList();
        },

        handleDescriptionClick() {
            this.$set(this.users, 'description', '');
        },

        handleBackClick() {
            if (isEqual(this.defaultUsers, this.users)) {
                this.handleConfirmBack();
            } else {
                this.toggleDialogVisible('backDialog', true);
            }
        },

        async handleSaveClick() {
            this.saving = true;
            const query = this.isCreated ? this.createUsergroup : this.updateUsergroup;
            const AUTH_TYPE = this.isCreated ? 'CREATE_USER_GROUP' : 'EDIT_USER_GROUP';
            try {
                const conditionType = usersConfig.audienceType.reduce((acc, item) => [...acc, ...(item.options.map((option) => option.code))], []);
                const condition = this.users?.content?.[0]?.children?.map?.((userGroup) => conditionType.includes(userGroup.type) ? userGroup.type : 'userAttribute');
                if (this.isCreated) {
                    var e = this.from || this.$route.params?.back || 'UserList';
                    const ep = {
                        UserList: 'ug_list',
                        CampaignEdit: 'cp_create_fromv2',
                        EngagementEdit: 'cp_create_fromv1'
                    };
                    analyticsService.usePTX('ug_add_success', { position: ep[e], condition: JSON.stringify(condition) }).track();
                } else {
                    analyticsService.usePTX('ug_edit', { position: 'ug_list', condition: JSON.stringify(condition) }).track();
                }
            } catch (e) {
                console.log(e);
            }
            const [err, data] = await commonUtils.awaitWrap(query());
            if (err) {
                const { code } = err;
                code === 'PTX_PACKAGE_LIMIT_ERROR'
                    ? this.SET_AUTH_DIALOG_VISIBLE({ visible: true, type: AUTH_TYPE })
                    : this.$message.error('save user group failed.');
                this.saving = false;
            } else {
                if (this.isCreated) {
                    typeof data?.count === 'number' &&
                        this.UPDATE_PACKAGE_USAGE({ type: 'userGroups', count: data.count });
                    analyticsService.usePTX('user_new_group').track();
                    this.guideInfo &&
                        this.guideInfo.guideType &&
                        this.updateProfileGuideInfo({ key: this.guideInfo.guideType, val: 1 });
                }
                const { id } = data;
                this.$router.push({
                    name: this.from || 'UsersSettingOverview',
                    params: { usersId: id }
                });
                // this.$router.push({
                //     name: this.from || 'UsersReport',
                //     params: { userGroupID: id, engageId: this.engageId }
                // });
                this.saving = false;
            }
        },

        handleIptBlur(event, type) {
            const val = event.target.value;
            val === '' ? this.$set(this.model, type, this.users[type]) : (this.users[type] = val);
        },

        /**
         * 返回事件
         */
        handleConfirmBack() {
            const { from, back } = this.$route.params;
            this.$router.push({
                name: from || back || 'UsersReport',
                params: { engageId: this.$route.params.engageId },
                query: this.$route.query
            });
        },

        handleFilterCreate() {
            let defaultValue = getDefaultValue();
            let filters = [].concat(this.users.content.children || []);
            filters.push(defaultValue);
            this.$set(this.users.content, 'children', filters);
        },

        // 更新字段字典表
        handleFilterItemsUpdate(code, value) {
            this.$set(this.valueOptions, code, value);
        },

        handletFilterDelete(index, val) {
            this.users.content.children.splice(index, 1);
        },

        handleFilterChange(index, filter) {
            this.$set(this.users.content.children, index, filter);
            this.UPDATE_USER_GROUP_INFO(this.users);
        },

        toggleDialogVisible(type, visible) {
            const timer = 301; //steps展开/收起动画时间为300;
            this.$set(this.dialogVisible, type, visible);
            type === 'description' &&
                this.$refs.usersScrollbar &&
                setTimeout(() => {
                    this.$refs.usersScrollbar.scrollToPos('top');
                }, timer);
        },

        createUsergroup() {
            return usersApis.createUsergroup(null, {
                data: {
                    sid: this.profileInfo.sid,
                    ...this.users
                }
            });
        },

        updateUsergroup() {
            const { id, name, description, content } = this.users;
            const data = {
                name,
                description,
                content
            };
            return usersApis.updateUsergroup(null, {
                data,
                where: {
                    id,
                    sid: this.profileInfo.sid
                }
            });
        },

        updateShowUserList(reset = false) {
            reset && ((this.userList.itemLen = 0), (this.userList.showData = []));
            const { data } = this.userList;
            if (!data) return;
            let itemLen = this.userList.itemLen + 5; //每次新增5条
            if (this.userList.itemLen === 0) {
                const docHeight = document.body.offsetHeight;
                const itemHeight = 125;
                const overviewLen = 1;
                const minLen = 6;
                itemLen = Math.max(minLen, parseInt(docHeight / itemHeight) + overviewLen);
                itemLen = parseInt(docHeight / itemHeight) + overviewLen;
            }
            itemLen = Math.min(itemLen, data.length);
            this.userList.showData.push(...data.slice(this.userList.itemLen, itemLen));
            this.userList.itemLen = itemLen;
        },

        updateHeaderPosition(scrollTop) {
            const headerDOM = this.$refs.header.$el;
            if (headerDOM) {
                const HeaderHeight = 60;
                const PageHeaderHeight = headerDOM.offsetHeight;
                const PageHeaderMargin = 32;
                if (scrollTop > PageHeaderHeight + PageHeaderMargin)
                    this.headerStyle.top = scrollTop - PageHeaderHeight + PageHeaderMargin + 'px';
                else this.headerStyle.top = '0';
            }
        }
    },

    watch: {
        users: {
            handler: function(newVal, oldVal) {
                const hasErrorOption = newVal.content.children.some(option => option.error.value);
                hasErrorOption || (this.fetchUserList(), this.fetchTotalsData());
            },
            deep: true
        }
    },

    components: {
        BackDialog,
        FilterContent,
        PageHeader,
        UserGroupTips
    }
};
</script>

<style lang="scss" module>
@import '@/styles/import.scss';

$arrow-width: 10px;
$arrow-height: 13px;

:global {
    .setting-description-enter-active,
    .setting-description-leave-active {
        opacity: 1;
        transform: scaleY(1);
        transition: transform 300ms cubic-bezier(0.23, 1, 0.32, 1), opacity 300ms cubic-bezier(0.23, 1, 0.32, 1);
        transform-origin: center top;
    }
    .setting-description-enter,
    .setting-description-leave-active {
        opacity: 0;
        transform: scaleY(0);
    }
}

.setting {
    padding-top: 170px;

    .header {
        position: absolute;
        width: 100%;
        padding: 0;
        z-index: 2;
        background-color: $pt-black-10;
        &-tools {
            display: flex;
            align-items: center;

            & > svg {
                margin-right: 18px;
                cursor: pointer;
                fill: $pt-black-600;

                &:hover {
                    fill: $pt-black-600;
                }
            }
        }
    }

    .description {
        position: relative;

        @include media-lg {
            .description-icon {
                min-width: 100px;

                svg {
                    width: 90px !important;
                    height: 90px !important;
                }
            }

            &-custom .description-icon svg {
                width: 50px !important;
                height: 50px !important;
            }
        }

        @include media-md {
            .description-icon {
                display: none;
            }
        }

        &-close {
            cursor: pointer;
            position: absolute;
            top: 16px;
            right: 16px;
            fill: $pt-black-90;

            &:hover {
                fill: $pt-black-300;
            }
        }

        &-custom {
            .description-icon svg {
                width: 60px !important;
                height: 60px !important;
            }
        }

        &-arrow {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 10px $arrow-height 10px;
            border-color: transparent transparent $pt-black-30 transparent;
            position: absolute;
            top: -($arrow-height - 1px);
            z-index: 1;

            &::after {
                content: '';
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 $arrow-width - 1px $arrow-height - 1px $arrow-width - 1px;
                border-color: transparent transparent $pt-black-30 transparent;
                position: absolute;
                left: -($arrow-width - 1px);
                top: 1px;
            }
        }

        &-container {
            padding: 54px 54px 36px 48px;
            background-color: $pt-black-30;
            border-radius: 4px;
            display: flex;
            position: relative;
            border: 1px solid $pt-black-30;
        }

        &-icon {
            flex: 0 0 14%;
            min-width: 130px;
            max-width: 155px;
        }

        &-info {
            h3,
            h4,
            h5 {
                color: $pt-black-600;
                font-size: 20px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 29px;
            }
            h4,
            h5 {
                font-size: 16px;
                line-height: 24px;
            }

            h5 {
                color: $pt-black-600;
                font-size: 12px;
                margin-top: 26px;
            }

            p,
            span,
            a {
                color: $pt-black-600;
                font-size: 14px;
                font-weight: 300;
                letter-spacing: 0;
                line-height: 20px;
                margin-top: 6px;
            }

            span,
            a {
                font-size: 12px;
                margin-top: 4px;
            }

            a {
                display: block;
                cursor: pointer;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        &-items {
            border-top: 1px solid $pt-black-50;
            margin-top: 16px;
            padding-top: 16px;
            display: grid;
            grid-auto-flow: column;
            grid-column-gap: 32px;
            grid-row-gap: 16px;

            @include media-md {
                .description-item {
                    grid-column-start: span 2;
                }
            }

            @include media-lg {
                // grid-auto-flow: row;
                // grid-template-columns: repeat(2, 1fr);

                .description-item:first-child {
                    grid-column-start: span 2;
                }
            }
        }
    }

    .container {
        margin-top: 52px;
    }

    .box {
        margin-bottom: 20px;

        & > h4 {
            color: $pt-black-600;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 22px;
        }

        & > button {
            margin: 16px 0 28px;
        }

        &-content {
            margin-top: 8px;
        }

        &-ipt {
            width: 100%;
            max-width: 600px;
        }

        &-textarea {
            height: 62px;
        }
    }

    .filter-add {
        border: none;
        background: transparent;
        @include link($pt-green-60, $pt-green-70);
    }

    .overview {
        margin-top: 68px;
        padding-bottom: $page-content-padding-bottom * 2;

        &-title {
            color: $pt-black-600;
            display: flex;
            flex-direction: column;
            margin-bottom: 60px;

            h4 {
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 24px;
            }

            p {
                margin-top: 10px;
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0;
                line-height: 19px;
                display: flex;
                align-items: center;

                i {
                    width: 1px;
                    height: 24px;
                    background-color: $pt-black-300;
                    margin: 0 20px;
                }
            }

            span {
                color: $pt-green-60;
                font-size: 28px;
                font-weight: 400;
                letter-spacing: 0;
                line-height: 38px;
                margin-left: 10px;
            }
        }

        &-box {
            box-sizing: border-box;
            height: 284px;
            border: 1px solid $pt-black-40;
            border-radius: 2px;
            background-color: $pt-white;

            &:not(:last-child) {
                margin-bottom: 40px;
            }
        }

        &-list {
            min-width: 600px;

            .row {
                color: $pt-black-600;
                font-size: 14px;
                line-height: 24px;
                display: grid;
                grid-template-columns: repeat(12, 1fr);
                border-top: 1px solid $pt-black-40;
                padding: 30px 0;

                &-header {
                    padding: 0;
                    border-top: none;
                    height: 30px;
                    font-size: 14px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 19px;
                }

                &:hover:not(.row-header) {
                    background-color: $pt-black-20;
                }
            }

            .cell {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                padding-right: 6px;

                &.icon {
                    @extend %flex-center;
                    padding: 0;

                    span {
                        height: 32px;
                        width: 32px;
                        border-radius: 50%;
                        color: $pt-white;
                        font-size: 16px;
                        letter-spacing: 0;
                        @extend %flex-center;
                    }
                }

                &.email {
                    font-size: 16px;
                }

                &-span1 {
                    grid-column-start: span 1;
                }
                &-span2 {
                    grid-column-start: span 2;
                }
                &-span3 {
                    grid-column-start: span 3;
                }
            }

            .lenTips {
                font-size: 12px;
                text-align: left;
                padding: 10px 0;
                color: $pt-black-60;
            }
        }
    }
}
</style>
